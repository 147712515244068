import { GoogleLogin } from '@react-oauth/google'
import React from 'react'
import { makeStyles } from '@material-ui/core'
import googleLoginButtonStyles from './googleLoginButton.styles'
import { googleLogin } from '../../../../../Apis/googleLogin/googleLogin'
import { useMutation } from 'react-query'
import useHistoryPush from '../../../../../hooks/useHistoryPush'
import decodeJwt from 'jwt-decode'
import {
  removeLocalStorageValue,
  setLocalStorageValue,
} from '../../../../../utils/helpers/localStorageHelperMethods'
import localStorageIds from '../../../../../utils/constants/localStorageIds'
import { useNotify } from 'react-admin'

const GoogleLoginButton = () => {
  const { redirect } = useHistoryPush()
  const notify = useNotify()
  const useStyles = makeStyles(googleLoginButtonStyles)
  const classes = useStyles()

  const googleLoginRequest = useMutation((data) => googleLogin(data), {
    onSuccess: (data) => {
      const { orgScopes, orgRoles, Subscriptions, isSuperAdmin } = decodeJwt(data?.accessToken)
      setLocalStorageValue([
        { id: localStorageIds.TOKEN, value: data.accessToken },
        { id: localStorageIds.SCOPES, value: orgScopes },
        { id: localStorageIds.USER_ORG_ROLES, value: orgRoles },
        { id: localStorageIds.SUBSCRIPTIONS, value: Subscriptions },
        { id: localStorageIds.INVITATIONS_SNACK_OPEN, value: false },
        {
          id: localStorageIds.IS_SUPER_ADMIN,
          value: JSON.parse(isSuperAdmin?.toLowerCase()),
        },
      ])

      removeLocalStorageValue([localStorageIds.IMPERSONATION])
      if (process.env.REACT_APP_RUNTIME === 'PROD') {
        posthog.identify(data?.userId)
      }

      // Dispatching for the components that listening storage event.
      window.dispatchEvent(new Event('storage'))
      redirect('/')
    },
  })

  const successResponse = async (response) => {
    googleLoginRequest.mutate(response)
  }

  const errorMessage = () => {
    notify(
      'Something went wrong connecting with your google account!',
      'error',
      undefined,
      false,
      5000,
    )
  }

  return (
    <div className={classes.buttonContainer}>
      <GoogleLogin
        onSuccess={successResponse}
        onError={errorMessage}
        theme='filled_blue'
        text='signin_with'
        width='350'
      />
    </div>
  )
}

export default GoogleLoginButton

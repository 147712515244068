import React from 'react'
import PropTypes from 'prop-types'
import { Table, TableBody, TableCell, TableHead, TableRow, makeStyles } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import useHistoryPush from '../../hooks/useHistoryPush'
import teamStatisticsPageStyles from './teamStatisticsPage.styles'
import statsHeaders from '../../utils/constants/statsHeaders'

const PlayersTable = ({ players, showValue }) => {
  const useStyles = makeStyles(teamStatisticsPageStyles)
  const classes = useStyles()
  const translate = useTranslate()
  const { redirect } = useHistoryPush()

  if (!players || players.length === 0) {
    return null
  }

  const playerObjects = players?.map((player) => {
    const playerStats = player.stats
    return {
      id: player.playerId,
      fullName: player.fullName,
      totalStats: {
        gamesPlayed: playerStats.gamesPlayed,
        gamesStarted: playerStats.gamesStarted,
        points: playerStats.points,
        twoPointer: playerStats.made2Pointers,
        threePointer: playerStats.made3Pointers,
        freeThrows: playerStats.made1Pointers,
        offensiveRebounds: playerStats.offensiveRebounds,
        defensiveRebounds: playerStats.defensiveRebounds,
        totalRebounds: playerStats.rebounds,
        assists: playerStats.assists,
        steals: playerStats.steals,
        turnOvers: playerStats.turnovers,
        blocksAgainst: playerStats.blocksAgainst,
        foulsCommited: playerStats.fouls,
        foulsDrawn: playerStats.foulsDrawn,
        pir: playerStats.pir,
      },
      avgStats: {
        gamesPlayed: playerStats.gamesPlayed,
        gamesStarted: playerStats.gamesStarted,
        points: playerStats.avgPoints,
        twoPointer: playerStats.avgMade2Pointers,
        threePointer: playerStats.avgMade3Pointers,
        freeThrows: playerStats.avgMade1Pointers,
        offensiveRebounds: playerStats.avgOffensiveRebounds,
        defensiveRebounds: playerStats.avgDefensiveRebounds,
        totalRebounds: playerStats.avgRebounds,
        assists: playerStats.avgAssists,
        steals: playerStats.avgSteals,
        turnOvers: playerStats.avgTurnovers,
        blocksAgainst: playerStats.avgBlocksAgainst,
        foulsCommited: playerStats.avgFouls,
        foulsDrawn: playerStats.avgFoulsDrawn,
        pir: playerStats.avgPir,
      },
    }
  })

  const headers = Object.keys(playerObjects[0]?.totalStats)

  const handleRowClick = (playerId) => {
    redirect(`/stats/player/${playerId}`)
  }

  return (
    <Table style={{ tableLayout: 'auto' }}>
      <TableHead>
        <TableRow style={{ backgroundColor: '#E0726E' }}>
          <TableCell align='left' className={classes.headerCell}>
            {translate('ra.text.players')}
          </TableCell>
          {headers.map((header) => (
            <TableCell key={header} align='center' className={classes.headerCell}>
              {statsHeaders[header]}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {playerObjects
          ?.sort((a, b) => a.fullName.localeCompare(b.fullName))
          ?.map((player) => (
            <TableRow
              key={player.id}
              className={classes.hoverRow}
              onClick={() => handleRowClick(player.id)}
              style={{ cursor: 'pointer' }}
            >
              <TableCell align='left'>{player.fullName}</TableCell>
              {headers?.map((header) => (
                <TableCell key={header} align='center' className={classes.tableCell}>
                  {showValue === 'average' ? player.avgStats[header] : player.totalStats[header]}
                </TableCell>
              ))}
            </TableRow>
          ))}
      </TableBody>
    </Table>
  )
}

PlayersTable.propTypes = {
  players: PropTypes.arrayOf(
    PropTypes.shape({
      playerId: PropTypes.string,
      fullName: PropTypes.string,
      jerseyNumber: PropTypes.number,
      gamesPlayed: PropTypes.number,
      gamesStarted: PropTypes.number,
      avgGamesStarted: PropTypes.number,
      gameStats: PropTypes.shape({
        points: PropTypes.number,
        avgPoints: PropTypes.number,
        twoPointer: PropTypes.number,
        avgTwoPoints: PropTypes.number,
        totalTwoPointsAttempts: PropTypes.number,
        threePointer: PropTypes.number,
        avgThreePoints: PropTypes.number,
        totalThreePointsAttempts: PropTypes.number,
        freeThrows: PropTypes.number,
        avgFreeThrows: PropTypes.number,
        totalFreeThrowsAttempts: PropTypes.number,
        offensiveRebounds: PropTypes.number,
        avgOffensiveRebounds: PropTypes.number,
        defensiveRebounds: PropTypes.number,
        avgDefensiveRebounds: PropTypes.number,
        totalRebounds: PropTypes.number,
        avgRebounds: PropTypes.number,
        assists: PropTypes.number,
        avgAssists: PropTypes.number,
        steals: PropTypes.number,
        avgSteals: PropTypes.number,
        turnOvers: PropTypes.number,
        avgTurnOvers: PropTypes.number,
        blocksAgainst: PropTypes.number,
        avgBlocksAgainst: PropTypes.number,
        foulsCommited: PropTypes.number,
        avgFoulsCommited: PropTypes.number,
        foulsDrawn: PropTypes.number,
        avgFoulsDrawn: PropTypes.number,
        pir: PropTypes.number,
        avgPIR: PropTypes.number,
      }),
    }),
  ),
  showValue: PropTypes.string,
}

export default PlayersTable

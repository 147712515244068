import COLORS from '../../utils/constants/colors'

const impersonationAlertStyles = {
  impersonationAlert: {
    backgroundColor: COLORS.errorRed,
    color: 'white',
    padding: '10px 0',
    margin: 'auto',
    textAlign: 'center',
    fontWeight: 'bold',
    width: '100%',
    zIndex: 9999,
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
  },
  impersonationAlertTitle: {
    display: 'inline-block',
    fontSize: '16px',
  },
}

export default impersonationAlertStyles

import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Card, CardContent, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import teamStatisticsPageStyles from './teamStatisticsPage.styles'
import MyError from '../../react-admin/components/MyError/MyError'

const StatsGrid = ({ stats, showValue = 'average' }) => {
  const useStyles = makeStyles(teamStatisticsPageStyles)
  const classes = useStyles()

  if (!stats || Object.keys(stats).length === 0) return <MyError />

  const displayedStats = [
    ['points', 'Points'],
    ['rebounds', 'Rebounds'],
    ['assists', 'Assists'],
    ['steals', 'Steals'],
    ['blocksAgainst', 'Blocks'],
    ['pir', 'Pir'],
  ]

  const displayedAvgStats = [
    ['avgPoints', 'Points'],
    ['avgRebounds', 'Rebounds'],
    ['avgAssists', 'Assists'],
    ['avgSteals', 'Steals'],
    ['avgBlocksAgainst', 'Blocks'],
    ['avgPir', 'Pir'],
  ]

  const selectedStats = showValue === 'average' ? displayedAvgStats : displayedStats

  return (
    <Grid container spacing={2}>
      {selectedStats.map(([statKey, displayName]) => (
        <Grid item xs={6} sm={4} md={3} lg={2} className={classes.statItem} key={statKey}>
          <Card className={classes.statCard}>
            <CardContent className={classes.cardContent}>
              <div className={classes.statCircleArcTop} />
              <Typography className={classes.statValue}>{stats[statKey]}</Typography>
              <Typography variant='body2' className={classes.statLabel}>
                {displayName}
              </Typography>
              <div className={classes.statCircleArcBottom} />
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  )
}

StatsGrid.propTypes = {
  stats: PropTypes.shape({
    teamId: PropTypes.string,
    gamesPlayed: PropTypes.number,
    wins: PropTypes.number,
    points: PropTypes.number,
    avgPoints: PropTypes.number,
    rebounds: PropTypes.number,
    avgRebounds: PropTypes.number,
    assists: PropTypes.number,
    avgAssists: PropTypes.number,
    steals: PropTypes.number,
    avgSteals: PropTypes.number,
    blocks: PropTypes.number,
    avgBlocks: PropTypes.number,
    fouls: PropTypes.number,
    avgFouls: PropTypes.number,
    turnovers: PropTypes.number,
    avgTurnovers: PropTypes.number,
    freeThrows: PropTypes.number,
    missedFreeThrows: PropTypes.number,
    avgMissedFreeThrows: PropTypes.number,
    twoPointer: PropTypes.number,
    missed2Pointers: PropTypes.number,
    avgMissed2Pointers: PropTypes.number,
    threePointer: PropTypes.number,
    missed3Pointers: PropTypes.number,
    avgMissed3Pointers: PropTypes.number,
    pir: PropTypes.number,
    avgPIR: PropTypes.number,
  }),
  showValue: PropTypes.string,
}

export default StatsGrid

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import impersonationAlertStyles from './impersonationAlert.styles'
import WarningIcon from '@material-ui/icons/Warning'
import useGetLocalStorage from '../../hooks/useGetLocalStorage'
import localStorageIds from '../../utils/constants/localStorageIds'

const ImpersonationAlert = ({ sideBarOpen }) => {
  const localStorageValue = useGetLocalStorage()
  const { [localStorageIds.IMPERSONATION]: impersonation } = localStorageValue || {}
  const useStyles = makeStyles(impersonationAlertStyles)
  const classes = useStyles()

  if (!impersonation) return <div></div>

  return (
    <div
      className={classes.impersonationAlert}
      style={{ marginBottom: sideBarOpen ? '10px' : '40px' }}
    >
      <WarningIcon />
      {sideBarOpen && (
        <span className={classes.impersonationAlertTittle}>You are impersonating a user</span>
      )}
    </div>
  )
}

ImpersonationAlert.propTypes = {
  sideBarOpen: PropTypes.bool,
}

export default ImpersonationAlert

import React from 'react'
import PropTypes from 'prop-types'
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { useTranslate } from 'react-admin'
import playerStatsTableStyles from './playerStatsTable.styles'
import statsHeaders from '../../utils/constants/statsHeaders'

const PlayerStatsTable = ({ playerData }) => {
  const useStyles = makeStyles(playerStatsTableStyles)
  const classes = useStyles()
  const translate = useTranslate()
  const headerCellsWithoutMin = Object.keys(statsHeaders).map((key) => (
    <TableCell key={key} className={classes.tableCell}>
      {statsHeaders[key]}
    </TableCell>
  ))

  const fmtRatio = (val, total) => (val == 0 ? '-' : `${val}/${total}`)
  const fmtMinutes = (val) => {
    const [_, mm, ss] = val.split(':')
    return `${mm}:${ss.split('.')[0]}`
  }

  const headerCellsWithoutMinGpGs = Object.keys(statsHeaders)
    .filter((key) => key !== 'gamesPlayed' && key !== 'gamesStarted')
    .map((key) => (
      <TableCell key={key} className={classes.tableCell}>
        {statsHeaders[key]}
      </TableCell>
    ))
  const player = playerData.playerStatsDto
  const playerStats = player.stats
  const playerObjects = {
    id: playerStats.playerId,
    fullName: playerStats.fullName,
    totalStats: [
      {
        gamesPlayed: playerStats.gamesPlayed,
        gamesStarted: playerStats.gamesStarted,
        minutes: fmtMinutes(playerStats.minutes),
        points: playerStats.points,
        twoPointer: playerStats.made2Pointers,
        threePointer: playerStats.made3Pointers,
        freeThrows: playerStats.made1Pointers,
        offensiveRebounds: playerStats.offensiveRebounds,
        defensiveRebounds: playerStats.defensiveRebounds,
        totalRebounds: playerStats.rebounds,
        assists: playerStats.assists,
        steals: playerStats.steals,
        turnOvers: playerStats.turnovers,
        blocksAgainst: playerStats.blocksAgainst,
        foulsCommited: playerStats.fouls,
        foulsDrawn: playerStats.foulsDrawn,
        pir: playerStats.pir,
      },
    ],
    avgStats: [
      {
        gamesPlayed: playerStats.gamesPlayed,
        gamesStarted: playerStats.gamesStarted,
        minutes: fmtMinutes(playerStats.avgMinutes),
        points: playerStats.avgPoints,
        twoPointer: playerStats.avgMade2Pointers,
        threePointer: playerStats.avgMade3Pointers,
        freeThrows: playerStats.avgMade1Pointers,
        offensiveRebounds: playerStats.avgOffensiveRebounds,
        defensiveRebounds: playerStats.avgDefensiveRebounds,
        totalRebounds: playerStats.avgRebounds,
        assists: playerStats.avgAssists,
        steals: playerStats.avgSteals,
        turnOvers: playerStats.avgTurnovers,
        blocksAgainst: playerStats.avgBlocksAgainst,
        foulsCommited: playerStats.avgFouls,
        foulsDrawn: playerStats.avgFoulsDrawn,
        pir: playerStats.avgPir,
      },
    ],
  }
  const mapPlayerObjectsToFormat = (playerData) => {
    const stats = playerData.teamPlayerStatsPerGame
      .filter((game) => game.opponentName !== null)
      .map((game) => {
        const playerStats = game.stats
        return {
          Game: `vs ${game.opponentName}`,
          MIN: fmtMinutes(playerStats.minutes),
          Pts: playerStats.points,
          FG2: fmtRatio(playerStats.made2Pointers, playerStats.attempted2Pointers),
          FG3: fmtRatio(playerStats.made3Pointers, playerStats.attempted3Pointers),
          FT: fmtRatio(playerStats.made1Pointers, playerStats.attempted1Pointers),
          OR: playerStats.offensiveRebounds,
          DR: playerStats.defensiveRebounds,
          TR: playerStats.rebounds,
          As: playerStats.assists,
          St: playerStats.steals,
          To: playerStats.turnovers,
          BLKA: playerStats.blocksAgainst,
          FC: playerStats.fouls,
          FD: playerStats.foulsDrawn,
          PIR: playerStats.pir,
        }
      })
    return {
      id: playerData.playerId,
      fullName: playerData.fullName,
      stats: stats,
    }
  }
  const mappedPlayerData = mapPlayerObjectsToFormat(playerData)
  const renderTableRows = (stats, title) => {
    return stats.map((row, index) => (
      <TableRow key={index}>
        {title && (
          <TableCell className={classes.tableCell}>
            <Typography className={classes.typo}>{title}</Typography>
          </TableCell>
        )}
        {Object.entries(row).map(([key, value]) => (
          <TableCell key={key} className={classes.tableCell}>
            {value}
          </TableCell>
        ))}
      </TableRow>
    ))
  }
  const validStats = mappedPlayerData.stats.filter((game) => game.MIN !== '00:00')
  const didNotPlayStats = mappedPlayerData.stats.filter(
    (game) => game.MIN === '00:00' || game.MIN === null,
  )
  const shouldShowGameStatsTable = mappedPlayerData.stats.length > 0
  return (
    <div>
      <Typography variant='h6' gutterBottom className={classes.marginTypo}>
        {translate('ra.text.seasonStats')}
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow className={classes.tableHeader}>
              <TableCell className={classes.tableCell} />
              {headerCellsWithoutMin}
            </TableRow>
          </TableHead>
          <TableBody>{renderTableRows(playerObjects.totalStats, 'Total')}</TableBody>
          <TableBody>{renderTableRows(playerObjects.avgStats, 'Average')}</TableBody>
        </Table>
      </TableContainer>
      {shouldShowGameStatsTable && (
        <>
          <Typography variant='h6' gutterBottom className={classes.marginTypo}>
            {translate('ra.text.gameStats')}
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow className={classes.tableHeader}>
                  <TableCell className={classes.tableCell} />
                  {headerCellsWithoutMinGpGs}
                </TableRow>
              </TableHead>
              <TableBody>
                {renderTableRows(validStats)}
                {didNotPlayStats.length > 0 &&
                  didNotPlayStats.map((game, index) => (
                    <TableRow key={index}>
                      <TableCell colSpan={1} className={classes.tableCell}>
                        {game.Game}
                      </TableCell>
                      <TableCell colSpan={15} className={classes.tableCell}>
                        {translate('ra.text.didNotPlay')}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  )
  return <div></div>
}

PlayerStatsTable.propTypes = {
  playerData: PropTypes.shape({
    playerStats: PropTypes.shape({
      playerId: PropTypes.string,
      fullName: PropTypes.string,
      opponentName: PropTypes.string,
      gamesPlayed: PropTypes.number,
      gamesStarted: PropTypes.number,
      avgGamesStarted: PropTypes.number,
      minutes: PropTypes.string,
      avgMinutes: PropTypes.string,
      gameStats: PropTypes.shape({
        points: PropTypes.number,
        avgPoints: PropTypes.number,
        twoPointer: PropTypes.number,
        avgTwoPoints: PropTypes.number,
        totalTwoPointsAttempts: PropTypes.number,
        threePointer: PropTypes.number,
        avgThreePoints: PropTypes.number,
        totalThreePointsAttempts: PropTypes.number,
        freeThrows: PropTypes.number,
        avgFreeThrows: PropTypes.number,
        totalFreeThrowsAttempts: PropTypes.number,
        offensiveRebounds: PropTypes.number,
        avgOffensiveRebounds: PropTypes.number,
        defensiveRebounds: PropTypes.number,
        avgDefensiveRebounds: PropTypes.number,
        totalRebounds: PropTypes.number,
        avgRebounds: PropTypes.number,
        assists: PropTypes.number,
        avgAssists: PropTypes.number,
        steals: PropTypes.number,
        avgSteals: PropTypes.number,
        turnOvers: PropTypes.number,
        avgTurnOvers: PropTypes.number,
        blocksAgainst: PropTypes.number,
        avgBlocksAgainst: PropTypes.number,
        foulsCommited: PropTypes.number,
        avgFoulsCommited: PropTypes.number,
        foulsDrawn: PropTypes.number,
        avgFoulsDrawn: PropTypes.number,
        pir: PropTypes.number,
        avgPIR: PropTypes.number,
        twoPointerGameRatio: PropTypes.string,
        threePointerGameRatio: PropTypes.string,
        freeThrowGameRatio: PropTypes.string,
      }),
    }),
    teamPlayerStatsPerGame: PropTypes.arrayOf(
      PropTypes.shape({
        playerId: PropTypes.string,
        fullName: PropTypes.string,
        opponentName: PropTypes.string,
        gamesPlayed: PropTypes.number,
        gamesStarted: PropTypes.number,
        minutes: PropTypes.string,
        gameStats: PropTypes.shape({
          points: PropTypes.number,
          avgPoints: PropTypes.number,
          twoPointer: PropTypes.number,
          avgTwoPoints: PropTypes.number,
          totalTwoPointsAttempts: PropTypes.number,
          threePointer: PropTypes.number,
          avgThreePoints: PropTypes.number,
          totalThreePointsAttempts: PropTypes.number,
          freeThrows: PropTypes.number,
          avgFreeThrows: PropTypes.number,
          totalFreeThrowsAttempts: PropTypes.number,
          offensiveRebounds: PropTypes.number,
          avgOffensiveRebounds: PropTypes.number,
          defensiveRebounds: PropTypes.number,
          avgDefensiveRebounds: PropTypes.number,
          totalRebounds: PropTypes.number,
          avgRebounds: PropTypes.number,
          assists: PropTypes.number,
          avgAssists: PropTypes.number,
          steals: PropTypes.number,
          avgSteals: PropTypes.number,
          turnOvers: PropTypes.number,
          avgTurnOvers: PropTypes.number,
          blocksAgainst: PropTypes.number,
          avgBlocksAgainst: PropTypes.number,
          foulsCommited: PropTypes.number,
          avgFoulsCommited: PropTypes.number,
          foulsDrawn: PropTypes.number,
          avgFoulsDrawn: PropTypes.number,
          pir: PropTypes.number,
          avgPIR: PropTypes.number,
        }),
      }),
    ),
  }),
}

export default PlayerStatsTable

import React from 'react'
import { useTranslate } from 'react-admin'
import { makeStyles } from '@material-ui/core'
import BarChart from '@material-ui/icons/BarChart'
import connectionErrorPageStyles from '../connectionError/connectionErrorPageStyles'
import COLORS from '../../utils/constants/colors'
import PropTypes from 'prop-types'

const NoStatsAvailablePage = ({ error }) => {
  const translate = useTranslate()
  const useStyles = makeStyles(connectionErrorPageStyles)
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <BarChart
        style={{
          width: '40%',
          height: '40%',
          fill: COLORS.orange,
          opacity: 0.5,
        }}
      />
      <div>{translate('ra.errorMessages.noStatsAvailable')}</div>
    </div>
  )
}

NoStatsAvailablePage.propTypes = {
  error: PropTypes.string,
}

export default NoStatsAvailablePage

import axiosInstance from '../axiosInterceptor/axiosInstance'

const impersonateUser = (data) => {
  const { userId } = data
  const url = `/auth/impersonate?userId=${userId}`

  return axiosInstance.post(url, null, { withCredentials: true })
}

export default impersonateUser
